import {TFunction} from "i18next";

export function validateMeterId(t: TFunction, value: string): string | undefined {
    return /^([0-9a-fA-F]{8}|\d{11})$/.test(value) ? undefined : t("org.config.wmbus.validation.meter-id", "Meter ID must be 8 hex characters (0-9,A-F,a-f), 11 digits, or empty") as string;
}

export function validateManufacturer(t: TFunction, value: string): string | undefined {
    return /^([A-Z]{3})$/.test(value) ? undefined : t("org.config.wmbus.validation.manufacturer", "Manufacturer ID must be 3 capital Letters or empty") as string;
}

export function validateMeterKey(t: TFunction, value: string): string | undefined {

    return /^[a-fA-F0-9]{32}$|^[a-fA-F0-9]{16}$|^[a-fA-F0-9]{2}[*]{28}[a-fA-F0-9]{2}|^[a-fA-F0-9]{2}[*]{12}[a-fA-F0-9]{2}/.test(value) ? undefined : t("org.config.wmbus.validation.key", "Key must be 8 or 16 bytes Hex (0-9, A-Z)") as string;
}

export function createValidator(t: TFunction, func: ValidatorFunction) {
    return (value: string) => {
        if (value) {
            const err = func(t, value)
            if (err) {
                return Promise.reject(err)
            }
        }
        return Promise.resolve()
    }
}

export type ValidatorFunction = (t: TFunction, value: string) => string | undefined