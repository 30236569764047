import { FieldArray, Formik, insert } from "formik"
import React from "react"
import * as Yup from "yup"
import { useMutation, useQuery } from "@apollo/client"
import { MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE } from "./queries"
import { Form } from "../common/ui/form/formik"
import {
    CancelButtonField,
    FormActions,
    Option,
    SldsButton,
    SldsCheckboxField,
    SldsFormElementCompound,
    SldsInputField,
    SldsSelectField,
    SldsTextareaField,
    SubmitButtonField,
} from "../common/ui/form/formElements"
import { Log } from "../common/log"
import Lookup from "../common/ui/lookup/lookup"
import { PillContainerField } from "../common/ui/form/pillContainerField"
import _ from "underscore"
import Button from "../common/slds/buttons/button"
import { ConfigPropertyType } from "../model/device"
import { useAuthContext } from "../common/context/authContext"
import gql from "graphql-tag"
import { useT } from "../common/i18n"
import OrganisationLookupField from "../components/organisation/organisationLookupField"
import { useOutletContext } from "react-router"

const TRAITS = [
    {
        type: "nb-iot-fota",
        displayName: "NB-IoT Firmware Update",
        description: "Allows Firmware updates via NB-IoT",
    },
    {
        type: "remote-config",
        displayName: "Remote Config Update",
        description: "Allows remote config updates",
    },
    {
        type: "wmbus-data",
        displayName: "wMbus Data",
        description: "Device can parse, decrypt and display wMbus Data",
    },
]

function getTrait(type) {
    return TRAITS.find((it) => it.type === type) || { type: type, displayName: "Unknown: " + type }
}

function evaluateOrganisation(initialDeviceType, valuesAfterEdit, auth) {
    if (!auth.hasRole("admin")) {
        return null
    }
    let orgId = valuesAfterEdit.organisation ? valuesAfterEdit.organisation.id : 0
    if (initialDeviceType.organisationId === orgId) {
        return null
    }
    return orgId
}

const UpdateDeviceTypeDialog = () => {
    const t = useT()
    const auth = useAuthContext()
    const { deviceType } = useOutletContext()
    useQuery(
        gql`
            query ($search: String) {
                getOrganisationList(search: $search) {
                    id
                    name
                }
            }
        `,
        {
            fetchPolicy: "network-only",
            variables: {
                page: {
                    offset: 0,
                    limit: 10,
                },
            },
        }
    )
    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: { id: deviceType.id },
        refetchQueries: [
            {
                query: QUERY_DEVICE_TYPE,
                variables: {
                    id: deviceType.id,
                },
            },
        ],
    })

    const makeTypePublic = () => {
        if (
            window.confirm(
                t(
                    "device-type.make-public.info-text",
                    "Device type can not be changed back to private!\n\nThe DeviceType will become public.  All Platform users in all organisation will be able to see information like the Name, Parser, Table Configs and so on. All organisations can assign Devices to use the DeviceType. Only Platform-Admins will be able to edit the DeviceType. Only Platform-Admins can change it back to a private Type."
                )
            )
        ) {
            updateDeviceType({
                variables: {
                    id: deviceType.id,
                    input: {
                        private: false,
                    },
                },
            }).catch((err) => {
                Log.Error("Failed to change device types visibility:", err)
                alert("Failed to save device type.")
            })
        }
    }

    const makeTypePrivate = () => {
        if (window.confirm(t("device-type.make-private.info-text", "Type will be private again! Be carefull if already used by other organisations!"))) {
            let input = {
                id: deviceType.id,
                input: {
                    private: true,
                },
            }
            if (deviceType.organisationId == null) {
                input.input.organisationId = auth.organisationId()
            }

            updateDeviceType({
                variables: input,
            }).catch((err) => {
                Log.Error("Failed to change device types visibility:", err)
                alert("Failed to save device type.")
            })
        }
    }
    let configProperties = []
    if (deviceType.configProperties) {
        let parsedProps = JSON.parse(deviceType.configProperties)
        if (_.isArray(parsedProps)) {
            configProperties = parsedProps
        }

        // Only for backwards compatibility of old format
        if (!_.isArray(parsedProps)) {
            for (let p in parsedProps) {
                configProperties.push({
                    name: p,
                    displayName: parsedProps[p].displayName,
                    type: parsedProps[p].type,
                    visible: parsedProps[p].visible,
                    exported: parsedProps[p].exported,
                })
            }
        }
    }
    Log.Debug("configProperties", configProperties, deviceType.configProperties)
    const canEdit = auth.hasRole("admin") || (deviceType.private && deviceType.organisationId == auth.organisationId() && auth.hasRole("org-admin"))
    return (
        <div>
            <Formik
                initialValues={{
                    ...deviceType,
                    private: deviceType.private.toString(),
                    configProperties: configProperties,
                }}
                initialStatus={{
                    readOnly: true,
                    canEdit: canEdit,
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().strict().shape({
                    displayName: Yup.string().required().trim(),
                })}
                onSubmit={(values, actions) => {
                    updateDeviceType({
                        variables: {
                            id: deviceType.id,
                            input: {
                                displayName: values.displayName,
                                description: values.description,
                                configProperties: JSON.stringify(values.configProperties),
                                deviceTraits: values.deviceTraits,
                                organisationId: evaluateOrganisation(deviceType, values, auth),
                                private: values.private === "true",
                            },
                        },
                    })
                        .then(() => {
                            actions.setStatus({ canEdit: true, readOnly: true })
                        })
                        .catch((err) => {
                            Log.Error("Failed to save device type:", err)
                            alert("Failed to save device type.")
                        })
                        .finally(() => {
                            actions.setSubmitting(false)
                        })
                }}
                render={(formik) => {
                    return (
                        <Form className="slds-m-around--x-small">
                            <SldsInputField name={"displayName"} label={"Display Name"} />
                            <SldsTextareaField name={"description"} label={"Description"} cols={3} />
                            <OrganisationLookupField />
                            <SldsFormElementCompound label="Traits">
                                {/* TODO: Use PillContainerField */}
                                <PillContainerField
                                    name={"deviceTraits"}
                                    pillLabelExtractor={(it) => getTrait(it).displayName}
                                    renderLookup={() => {
                                        return (
                                            <Lookup
                                                placeholder={"Add Trait ..."}
                                                titleExtractor={(item) => item && getTrait(item).displayName}
                                                subtitleExtractor={(item) => item}
                                                onLookup={(value) => {
                                                    let list = formik.values["deviceTraits"] || []
                                                    formik.setFieldValue("deviceTraits", insert(list, list.length, value))
                                                }}
                                                loadSuggestions={(value) => {
                                                    Log.Debug("Suggestions", value)
                                                    const search = value.toLowerCase()
                                                    return TRAITS.map((t) => {
                                                        const displayName = getTrait(t.type).displayName.toLowerCase()
                                                        const type = t.type.toLowerCase()

                                                        if (type.includes(search) || displayName.includes(search)) {
                                                            return t.type
                                                        }
                                                    })
                                                        .filter((it) => !!it)
                                                        .filter((item) => {
                                                            let traits = formik.values.deviceTraits || []
                                                            return traits.findIndex((it) => item === it) === -1
                                                        })
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </SldsFormElementCompound>
                            <FieldArray
                                name={"configProperties"}
                                render={(arrayHelpers) => {
                                    return (
                                        <SldsFormElementCompound label="Config Properties">
                                            {formik.values.configProperties.map((configProperty, index) => (
                                                <div className="slds-form-element__row" key={index}>
                                                    <div className="slds-size_3-of-8 slds-p-horizontal_x-small">
                                                        <SldsInputField label="Name" name={`configProperties.${index}.name`} required={true} />
                                                    </div>
                                                    <div className="slds-size_2-of-8 slds-p-horizontal_x-small">
                                                        <SldsInputField label="Display Name" name={`configProperties.${index}.displayName`} required={false} />
                                                    </div>
                                                    <div className="slds-size_1-of-8 slds-p-horizontal_x-small">
                                                        <SldsSelectField label="Type" name={`configProperties.${index}.type`} required={true}>
                                                            {_.keys(ConfigPropertyType).map((key) => (
                                                                <Option key={key} value={ConfigPropertyType[key].value}>
                                                                    {ConfigPropertyType[key].label}
                                                                </Option>
                                                            ))}
                                                        </SldsSelectField>
                                                    </div>
                                                    <div className="slds-size_1-of-16">
                                                        <SldsCheckboxField name={`configProperties.${index}.visible`} label={"visible"} />
                                                    </div>
                                                    <div className="slds-size_1-of-16">
                                                        <SldsCheckboxField name={`configProperties.${index}.exported`} label={"exported"} />
                                                    </div>

                                                    {!formik.status.readOnly && (
                                                        <div className="slds-form-element slds-size_1-of-10 slds-p-horizontal_large">
                                                            <div className="slds-align_absolute-center" >
                                                                <ul className="slds-button-group-list slds-p-top_medium">
                                                                    <li>
                                                                        <SldsButton
                                                                            iconCategory={"utility"}
                                                                            iconName={"arrowup"}
                                                                            iconVariant={"border"}
                                                                            variant={"icon"}
                                                                            onClick={() => arrayHelpers.move(index, index - 1)}
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <SldsButton
                                                                            iconCategory={"utility"}
                                                                            iconName={"arrowdown"}
                                                                            iconVariant={"border"}
                                                                            variant={"icon"}
                                                                            onClick={() => arrayHelpers.move(index, index + 1)}
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <SldsButton
                                                                            className={"slds-button_icon-error"}
                                                                            iconCategory={"utility"}
                                                                            iconName={"delete"}
                                                                            iconVariant={"border-filled"}
                                                                            variant={"icon"}
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(index)
                                                                                Log.Debug("REMOVE", index, configProperties)
                                                                            }}
                                                                        />
                                                                    </li>
                                                                </ul>

                                                                <ul className="slds-button-group-list slds-p-top_medium">
                                                                    <li>
                                                                        <SldsButton
                                                                            iconCategory={"utility"}
                                                                            iconName={"add"}
                                                                            label={"Add Column"}
                                                                            onClick={() => arrayHelpers.insert(index, {name:"", displayName:"", type:"Int32"})}>
                                                                            {t("data-table-config-form.add-column", "Add Column")}
                                                                        </SldsButton>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            {formik.status.readOnly === false && (
                                                <Button
                                                    variant={"icon"}
                                                    iconName={"add"}
                                                    iconSize={"small"}
                                                    onClick={() => {
                                                        arrayHelpers.push({ name: "", type: "Int32" })
                                                    }}>
                                                    Add Property
                                                </Button>
                                            )}
                                        </SldsFormElementCompound>
                                    )
                                }}
                            />
                            <FormActions>
                                <SubmitButtonField />
                                <CancelButtonField />
                            </FormActions>
                        </Form>
                    )
                }}
            />
            {deviceType.private === true && (auth.hasRole("admin") || auth.hasRole("org-admin")) ? (
                <Button iconName="edit" onClick={() => makeTypePublic()}>
                    {t("device-type.make-public.button", "Make Public")}
                </Button>
            ) : null}
            {deviceType.private === false && auth.hasRole("admin") ? (
                <Button iconName="edit" onClick={() => makeTypePrivate()}>
                    {t("device-type.make-private.button", "Make Private")}
                </Button>
            ) : null}
        </div>
    )
}

export default UpdateDeviceTypeDialog
